// share okna
function share() {
  // otevření okna
  Array.from(document.getElementsByClassName('js-share-open')).forEach((element) => {
    element.addEventListener('click', function (event) {
      event.stopPropagation();
      event.preventDefault();
      // otevření okna
      this.nextElementSibling.classList.add('is-open');
    });
  });

  // zavření share okna
  Array.from(document.getElementsByClassName('js-share-close')).forEach((element) => {
    element.addEventListener('click', function (event) {
      event.preventDefault();
      this.closest('.share').classList.remove('is-open');
    });
  });

  // zamezení zavření po kliknutí na tělo share
  Array.from(document.getElementsByClassName('share')).forEach((element) => {
    element.addEventListener('click', function (event) {
      event.preventDefault();
      event.stopPropagation();
    });
  });
}
