// modal okna
function modal() {
  // otevření okna
  Array.from(document.getElementsByClassName('js-modal-open')).forEach((element) => {
    element.addEventListener('click', function () {
      // zjištění ID okna z atributu rel
      let modalName = this.getAttribute('href').substring(1);

      // otevření okna
      document.getElementById(modalName).classList.add('is-open');
      document.body.classList.add('is-unscrollable');
    });
  });

  // zavření modal okna
  Array.from(document.getElementsByClassName('js-modal-close')).forEach((element) => {
    element.addEventListener('click', function (event) {
      event.preventDefault();
      this.closest('.modal').classList.remove('is-open');
      document.body.classList.remove('is-unscrollable');
    });
  });

  // zavření okna kliknutím na pozadí
  Array.from(document.getElementsByClassName('modal')).forEach((element) => {
    element.addEventListener('click', function (event) {
      event.preventDefault();
      this.classList.remove('is-open');
      document.body.classList.remove('is-unscrollable');
    });
  });

  // zamezení zavření po kliknutí na tělo modalu
  Array.from(document.getElementsByClassName('modal__body')).forEach((element) => {
    element.addEventListener('click', function (event) {
      event.stopPropagation();
    });
  });
}
