// taby
function tab(subscriber = (value) => {}) {
  function TabSettings() {
    let name = null;

    Object.defineProperty(this, 'name', {
      get: function () {
        return name;
      },
      set: function (value) {
        name = value;
        subscriber(value);
      },
    });
  }

  window.tabSettings = window.tabSettings || new TabSettings();

  // otevření okna
  Array.from(document.getElementsByClassName('js-tab-open')).forEach((element) => {
    element.addEventListener('click', function (event) {
      event.preventDefault();
      // zjištění ID okna z atributu rel
      let tabName = this.getAttribute('href').substring(1);

      document.querySelectorAll('.js-tab-open').forEach(function (element) {
        element.classList.remove('is-active');
      });

      element.classList.add('is-active');

      // zavřeme všechny ostatní taby
      document.querySelectorAll('.tab-content').forEach(function (element) {
        element.classList.remove('is-open');
      });
      // otevření tabu
      document.getElementById(tabName).classList.add('is-open');

      window.tabSettings.name = tabName;
    });
  });
}
