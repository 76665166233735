function bindCalendarEventDayClick() {
  Array.from(document.getElementsByClassName('day--single-day-event')).forEach((elem) =>
    elem.addEventListener('click', function (e) {
      e.preventDefault();
      Array.from(document.getElementsByClassName('day--active')).forEach((e) => e.classList.remove('day--active'));

      elem.parentElement.classList.add('day--active');

      const tabName = `#tab-day-${elem.dataset.date}`;

      toggleTab(tabName);
    })
  );
}

function updateEventCalendarOnTabChange(tabName) {
  const eventsTabTypes = Object.freeze({
    TODAY: 'TODAY',
    TOMORROW: 'TOMORROW',
    WEEK: 'WEEK',
    MONTH: 'MONTH',
    LAST: 'LAST',
    CHOSEN_DAY: 'CHOSEN_DAY',
  });

  tabName = tabName.startsWith('tab-day') ? 'tab-day' : tabName;

  if (tabName != 'tab-day') {
    Array.from(document.querySelectorAll('[href*="#tab-day"]')).forEach((e) => e.classList.add('is-hidden'));
  }

  const calendarTypeByTabName =
    {
      'tab-dnes': eventsTabTypes.TODAY,
      'tab-zitra': eventsTabTypes.TOMORROW,
      'tab-tento-tyden': eventsTabTypes.WEEK,
      'tab-tento-mesic': eventsTabTypes.MONTH,
      'tab-probehlo': eventsTabTypes.LAST,
      'tab-day': eventsTabTypes.CHOSEN_DAY,
    }[tabName] ?? eventsTabTypes.WEEK;

  const calendar = document.getElementsByClassName('calendar')[0];

  const calendarOldMode = [...calendar.classList].find((cls) => cls.startsWith('calendar--mode-'));
  const calendarNewMode = calendarOldMode.replace(
    /calendar--mode-\w+/,
    {
      [eventsTabTypes.TODAY]: 'calendar--mode-today',
      [eventsTabTypes.TOMORROW]: 'calendar--mode-tomorrow',
      [eventsTabTypes.WEEK]: 'calendar--mode-week',
      [eventsTabTypes.MONTH]: 'calendar--mode-month',
      [eventsTabTypes.LAST]: 'calendar--mode-inactive',
      [eventsTabTypes.CHOSEN_DAY]: 'calendar--mode-day',
    }[calendarTypeByTabName]
  );

  calendar.classList.replace(calendarOldMode, calendarNewMode);
}

function filterEvents() {
  const loading = document.querySelector('.loading');
  loading.classList.remove('hidden');

  const searchParams = new URLSearchParams(window.location.search);

  fetch(
    window.location.pathname +
      '?' +
      new URLSearchParams({
        search: searchParams.get('search'),
        'category.id': searchParams.get('category'),
      }).toString(),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
      },
    }
  )
    .then((response) => response.text())
    .then((data) => {
      const doc = new DOMParser().parseFromString(data, 'text/html');

      const calendar = doc.getElementsByClassName('calendar')[0];
      const tab = doc.getElementsByClassName('tab')[0];

      const oldTab = document.getElementsByClassName('tab')[0];

      document.getElementsByClassName('calendar')[0].replaceWith(calendar);
      oldTab.replaceWith(tab);

      bindEventsPageListenersAfterUpdate();

      const oldTabActive = oldTab.getElementsByClassName('is-active')[0];
      const tabName = oldTabActive ? oldTabActive.getAttribute('href') : null;
      if (tabName) toggleTab(tabName);

      loading.classList.add('hidden');
    })
    .catch((error) => {
      console.error('Error:', error);

      loading.classList.add('hidden');
    });
}

function bindSearchEventsListener() {
  document.getElementById('search-btn-events').addEventListener('click', function (e) {
    e.preventDefault();

    const search = document.getElementById('search-input-events').value;

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('search', search);
    window.history.replaceState(null, '', window.location.pathname + '?' + searchParams.toString());

    filterEvents();
  });
}

function bindEventCategoryChange() {
  document.querySelector('[name="event-categories"]').addEventListener('change', (e) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('category', e.target.value);
    window.history.replaceState(null, '', window.location.pathname + '?' + searchParams.toString());

    filterEvents();
  });
}

function toggleTab(tabName) {
  const currentTab = document.getElementsByClassName('tab-nav')[0].getElementsByClassName('is-active')[0];
  const currentTabName = currentTab ? currentTab.getAttribute('href').substring(1) : '';

  if (currentTabName == tabName) return;
  if (currentTabName.startsWith('tab-day')) currentTab.classList.add('is-hidden');

  const tab = document.querySelector(`[href='${tabName}']`);

  tab.classList.remove('is-hidden');
  tab.click();

  return tab;
}

function bindEventsPageListenersAfterUpdate() {
  tab(updateEventCalendarOnTabChange);
  bindCalendarEventDayClick();
}
