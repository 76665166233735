document.addEventListener('DOMContentLoaded', () => {
  // obecne
  let bodyWidth = window.innerWidth;

  // události při resize okna
  window.addEventListener('resize', function () {
    // osetreni, zda se velikost zmenila
    if (bodyWidth !== window.innerWidth) {
      // nastavíme novou šířku
      bodyWidth = window.innerWidth;
      // zresetuj menu
      resetMenu();
      // check navigation overflow
      checkNavOverflow();
    }
  });
  // mobilní menu
  function switchMenu() {
    // označíme zda je menu zavřeno či nikoliv
    if (document.querySelector('.nav-switcher').classList.contains('is-open')) {
      document.querySelector('.nav-switcher').classList.remove('is-open');
      document.querySelector('.nav').classList.remove('is-open');
      document.querySelector('.header__blur').classList.remove('is-active');
      document.body.classList.remove('is-unscrollable');
    } else {
      document.querySelector('.nav-switcher').classList.add('is-open');
      document.querySelector('.nav').classList.add('is-open');
      document.querySelector('.header__blur').classList.add('is-active');
      document.body.classList.add('is-unscrollable');
    }
  }
  // při změně rozlišení resetujeme menu
  function resetMenu() {
    document.querySelector('.nav-switcher').classList.remove('is-open');
    document.querySelector('.nav').classList.remove('is-open');
    document.querySelector('.header__blur').classList.remove('is-active');
    document.querySelector('.search-widget').classList.remove('is-open');
    document.querySelectorAll('.nav__link-wrap').forEach(function (element) {
      element.classList.remove('is-open');
      element.blur();
    });
    if (document.querySelector('.js-lang-toggle')) {
      document.querySelector('.js-lang-toggle').classList.remove('is-active');
    }
    document.body.classList.remove('is-unscrollable');
    if (document.querySelector('.search')) {
      document.querySelectorAll('.search').forEach(function (element) {
        element.classList.remove('is-active');
      });
    }
    if (document.querySelector('.share')) {
      document.querySelectorAll('.share').forEach(function (element) {
        element.classList.remove('is-open');
      });
    }
    // zresetuj custom select
    closeAllSelect();
  }

  Array.from(document.getElementsByClassName('nav__link')).forEach((element) => {
    element.addEventListener('click', function () {
      if (this.closest('.nav__link-wrap').classList.contains('is-open')) {
        document.querySelectorAll('.nav__link-wrap').forEach((wrap) => {
          wrap.classList.remove('is-open');
        });
      } else {
        document.querySelectorAll('.nav__link-wrap').forEach((wrap) => {
          wrap.classList.remove('is-open');
        });
        this.closest('.nav__link-wrap').classList.add('is-open');
      }
    });

    element.addEventListener('mouseenter', function (event) {
      event.preventDefault();
      if (window.matchMedia('(min-width: 64rem)').matches) {
        resetMenu();
      }
    });
  });

  Array.from(document.getElementsByClassName('nav__submenu-title')).forEach((element) => {
    element.addEventListener('click', function (event) {
      event.preventDefault();
      this.closest('.nav__link-wrap').classList.remove('is-open');
      document.querySelectorAll('.js-lang-toggle').forEach((wrap) => {
        wrap.classList.remove('is-active');
      });
    });
  });

  // search toggle
  if (document.querySelector('.js-search-toggle')) {
    document.querySelectorAll('.js-search-toggle').forEach((element) => {
      element.addEventListener('click', function () {
        if (document.querySelector('.search-widget').classList.contains('is-open')) {
          document.querySelector('.search-widget').classList.remove('is-open');
          document.querySelector('.header__blur').classList.remove('is-active');
          document.body.classList.remove('is-unscrollable');
        } else {
          resetMenu();
          document.querySelector('.search-widget').classList.add('is-open');
          document.querySelector('.header__blur').classList.add('is-active');
          document.body.classList.add('is-unscrollable');
        }
      });
    });
  }

  // zobrazení našeptáveče (pouze demo účely)
  if (document.querySelector('.js-search-whisper')) {
    const searchInputs = document.querySelectorAll('.js-search-whisper');

    searchInputs.forEach((searchInput) => {
      function showSearchWhisper() {
        const searchWhisper = searchInput.closest('.search');
        // Check if the input has at least one character
        if (searchInput.value.length > 0) {
          // Add the 'is-active' class if input is not empty
          searchWhisper.classList.add('is-active');
        } else {
          // Remove the 'is-active' class if input is empty
          searchWhisper.classList.remove('is-active');
        }
      }
      // Add event listener to each search input
      searchInput.addEventListener('input', showSearchWhisper);
    });
  }

  // výběr jazyk lang toggle
  if (document.querySelector('.js-lang-toggle')) {
    document.querySelectorAll('.js-lang-toggle').forEach((element) => {
      element.addEventListener('click', function (event) {
        event.preventDefault();
        if (document.querySelector('.nav__link-wrap--lang').classList.contains('is-open')) {
          document.querySelector('.nav__link-wrap--lang').classList.remove('is-open');
          document.querySelector('.header__blur').classList.remove('is-active');
          document.body.classList.remove('is-unscrollable');
          element.classList.remove('is-active');
        } else {
          //resetMenu();
          document.querySelectorAll('.nav__link-wrap').forEach((wrap) => {
            wrap.classList.remove('is-open');
          });
          document.querySelector('.nav__link-wrap--lang').classList.add('is-open');
          document.querySelector('.header__blur').classList.add('is-active');
          document.body.classList.add('is-unscrollable');
          element.classList.add('is-active');
          element.blur();
        }
      });
    });
  }

  // diskuze odpovědět
  if (document.querySelector('.reply-simple')) {
    document.querySelectorAll('.js-discuss-reply').forEach((element) => {
      element.addEventListener('click', function (event) {
        event.preventDefault();
        this.classList.add('is-hidden');
        let nextSibling = this.nextElementSibling;
        if (nextSibling && nextSibling.classList.contains('reply-simple')) {
          nextSibling.classList.add('is-open');
          var textarea = nextSibling.querySelector('textarea');
          if (textarea) {
            textarea.focus();
          }
        }
      });
    });
  }

  // diskuze odpovědět
  if (document.querySelector('.reply')) {
    let replyTextareas = document.querySelectorAll('.reply textarea');
    let replyButtons = document.querySelectorAll('.reply button');

    replyTextareas.forEach((textarea, index) => {
      let button = replyButtons[index];
      textarea.addEventListener('input', function () {
        if (textarea.value.trim() !== '') {
          button.removeAttribute('disabled');
        } else {
          button.setAttribute('disabled', 'disabled');
        }
      });
    });
  }

  // reset menu po kliknutí na main
  document.querySelector('main').addEventListener('click', function () {
    resetMenu();
  });

  document.querySelectorAll('.nav a').forEach((element) => {
    element.addEventListener('click', function () {
      resetMenu();
    });
  });

  // spouštěč
  document.querySelector('.nav-switcher').addEventListener('click', function () {
    switchMenu();
  });

  // události při načtení stránky
  window.addEventListener('load', function () {
    // AOS fix pro první načítání
    // AOS.refresh();
    checkNavOverflow();
  });

  // události při scroolování
  window.addEventListener('scroll', function () {
    //...
  });

  // modal okna
  if (document.querySelector('.modal')) {
    modal();
  }

  // sdílení
  if (document.querySelector('.share')) {
    share();
  }

  // taby
  if (document.querySelector('.tab')) {
    if (document.querySelector('.events-page')) {
      tab(updateEventCalendarOnTabChange);
    } else {
      tab();
    }
  }

  // slider
  if (document.querySelector('.slider')) {
    const slider = new Glider(document.querySelector('.slider'), {
      slidesToShow: 1.2,
      slidesToScroll: 1,
      draggable: true,
      dots: '.slider__dots',
      scrollLock: false,
      scrollLockDelay: 0,
      rewind: false,
      arrows: {
        prev: '.slider__btn-prev',
        next: '.slider__btn-next',
      },
      responsive: [
        {
          // screens greater than >= 576px
          breakpoint: 576,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 2.3,
          },
        },
        {
          // screens greater than >= 768px
          breakpoint: 768,
          settings: {
            slidesToShow: 2.3,
            slidesToScroll: 1,
            scrollLock: true,
          },
        },
        {
          // screens greater than >= 1200px
          breakpoint: 1200,
          settings: {
            slidesToShow: 'auto',
            slidesToScroll: 1,
            itemWidth: 488,
            exactWidth: true,
          },
        },
      ],
    });
    //gliderAutoplay(slider, { interval: 6000 });
  }

  // faq
  if (document.querySelector('.faq')) {
    faqQuestion = document.getElementsByClassName('faq__question');
    for (i = 0; i < faqQuestion.length; i++) {
      faqQuestion[i].addEventListener('click', function () {
        if (this.closest('.faq__item').classList.contains('is-open')) {
          this.closest('.faq__item').classList.remove('is-open');
        } else {
          this.closest('.faq__item').classList.add('is-open');
        }
      });
    }
  }

  // galerie
  if (document.querySelector('.gallery')) {
    // galerie SimpleLightBox
    document.querySelectorAll('.gallery__wrap').forEach(function (galleryLightbox) {
      new SimpleLightbox(galleryLightbox.querySelectorAll('a'), {
        showCounter: true,
        loop: false,
      });
    });

    // galerie slider (Glider.js)
    document.querySelectorAll('.gallery').forEach(function (slider, index) {
      const gallerySlider = slider.querySelector('.gallery__slider');
      const galleryImages = Array.from(slider.querySelectorAll('.gallery__image'));
      const gallerySliderItems = Array.from(slider.querySelectorAll('.gallery__slider-item'));

      let activeIndex = 0;

      const prevButton = slider.querySelector('.gallery__prev');
      const nextButton = slider.querySelector('.gallery__next');

      const glider = new Glider(gallerySlider, {
        slidesToShow: 4,
        slidesToScroll: 1,
        draggable: true,
        arrows: {
          prev: prevButton,
          next: nextButton,
        },
        responsive: [
          {
            // screens greater than >= 575px
            breakpoint: 575,
            settings: {
              slidesToShow: 'auto',
              slidesToScroll: 'auto',
              itemWidth: 80,
            },
          },
          {
            // screens greater than >= 1024px
            breakpoint: 1024,
            settings: {
              slidesToShow: 'auto',
              slidesToScroll: 'auto',
              itemWidth: 100,
            },
          },
        ],
      });

      // Update the visibility of the images based on the current active index
      function updateGalleryImages() {
        galleryImages.forEach((img, imgIndex) => {
          if (imgIndex === activeIndex) {
            img.classList.remove('is-hidden');
          } else {
            img.classList.add('is-hidden');
          }
        });
      }

      // Update the state of the prev and next buttons
      function updateButtons() {
        if (activeIndex === 0) {
          prevButton.classList.add('disabled-noclick');
        } else {
          prevButton.classList.remove('disabled-noclick');
        }

        if (activeIndex === gallerySliderItems.length - 1) {
          nextButton.classList.add('disabled-noclick');
        } else {
          nextButton.classList.remove('disabled-noclick');
        }
      }

      // Set the initial active image and button states
      updateGalleryImages();
      updateButtons();

      // Update the 'is-active' class and gallery images when a slider item is clicked
      gallerySliderItems.forEach((element, idx) => {
        element.addEventListener('click', function (event) {
          event.preventDefault();

          // Update the active index
          activeIndex = idx;

          // Add 'is-active' class to the clicked slider item and remove it from others
          gallerySliderItems.forEach((item) => item.classList.remove('is-active'));
          this.classList.add('is-active');

          // Update the gallery images and button states
          updateGalleryImages();
          updateButtons();
        });
      });

      // Add event listeners for 'prev' and 'next' buttons
      prevButton.addEventListener('click', function () {
        if (activeIndex > 0) {
          activeIndex--;
          updateGalleryImages();
          updateButtons();
          gallerySliderItems.forEach((item) => item.classList.remove('is-active'));
          gallerySliderItems[activeIndex].classList.add('is-active');
        }
      });

      nextButton.addEventListener('click', function () {
        if (activeIndex < gallerySliderItems.length - 1) {
          activeIndex++;
          updateGalleryImages();
          updateButtons();
          gallerySliderItems.forEach((item) => item.classList.remove('is-active'));
          gallerySliderItems[activeIndex].classList.add('is-active');
        }
      });
    });
  }

  // intro pano
  if (document.querySelector('.intro__pano') && document.getElementById('pano')) {
    pano();
  }

  // slider
  if (document.querySelector('.intro__carousel')) {
    const slider = new Glider(document.querySelector('.intro__carousel'), {
      slidesToShow: 1,
      slidesToScroll: 1,
      scrollLock: true,
      dots: '.intro__dots',
      rewind: true,
    });
    gliderAutoplay(slider, { interval: 6000 });
  }

  if (document.querySelector('.events-page')) {
    bindCalendarEventDayClick();
    bindSearchEventsListener();
    bindEventCategoryChange();
  }

  // navigation overflow
  if (document.querySelector('.nav')) {
    function checkNavOverflow() {
      const nav = document.querySelector('.nav');
      const navWraps = [...document.querySelectorAll('button.nav__link-wrap')];
      const moreWrap = document.querySelector('button.nav__link-wrap--more');
      const moreGrid = moreWrap.querySelector('.nav__grid');

      // Reset any changes
      navWraps.forEach((item) => item.classList.remove('is-hidden'));
      moreWrap.classList.add('is-hidden');
      moreGrid.innerHTML = ''; // Clear the more grid

      if (window.innerWidth >= 1024) {
        let isOverflowing = nav.scrollWidth > nav.clientWidth;

        if (isOverflowing) {
          // Show 'More' button
          moreWrap.classList.remove('is-hidden');

          let visibleCount = navWraps.length;

          for (let i = navWraps.length - 1; i >= 0; i--) {
            navWraps[i].classList.add('is-hidden');
            isOverflowing = nav.scrollWidth > nav.clientWidth;

            // Collect the links from the current navWrap
            const hiddenLinks = navWraps[i].querySelectorAll('a.nav__link');
            const batch = []; // Collect all links in this batch

            hiddenLinks.forEach((link) => {
              batch.push(link.cloneNode(true)); // Clone and store the links in the batch
            });

            // Prepend the batch of links all at once
            batch.reverse().forEach((link) => {
              moreGrid.prepend(link); // Add each link in the batch in reverse order to keep the correct sequence
            });

            // Count visible navWraps
            visibleCount = navWraps.filter((wrap) => !wrap.classList.contains('is-hidden')).length;
            if (visibleCount <= 2 || !isOverflowing) {
              break;
            }
          }
        }
      }
    }
  }
});
